<template>
  <div v-if="outfitCategorys && dataOutfitPage">
    <header-page-derek
      :title="outfitTitle"
      :description="
        '¿Sabes que existe un outfit perfecto para cada ocasión, momento o día? Descubre la combinación perfecta de prendas en un solo lugar'
      "
      :skeleton="false"
    />
    <div class="nav-outfit container-max-width">
      <ul class="item-nav-outfit">
        <router-link :to="{ name: 'Outfits' }" class="category-title-outfit">
          <li class="pe-3">
            <p
              class="name-category-outfit"
              :class="categorySelected == '' ? 'select-outfit' : ''"
            >
              Todos las campañas
            </p>
          </li>
        </router-link>
        <div v-for="(category, index) in outfitCategorys" :key="index">
          <router-link
            :to="{
              name: 'OutfitsCategory',
              params: { category: category.slug },
            }"
            class="category-title-outfit"
          >
            <li class="pe-3 d-flex align-items-center">
              <p class="pe-3 mb-0">/</p>
              <p
                class="name-category-outfit"
                :class="
                  category.slug == categorySelected ? 'select-outfit' : ''
                "
              >
                {{ category.descripcion }}
              </p>
            </li>
          </router-link>
        </div>
      </ul>
    </div>
    <div>
      <div class="container-max-width">
        <card-outfit
          v-for="(outfit, index) in dataOutfitPage.data.slice(0, 3)"
          :id="outfit.slug"
          :key="index + 'asd'"
          :switch-side="index % 2 == 0"
          :data-outfit="outfit"
        />
      </div>
      <breakin-cupo class="mt-5" />
      <div v-if="dataOutfitPage.data.length > 3" class="container-max-width">
        <card-outfit
          v-for="(outfit, index) in dataOutfitPage.data.slice(
            3,
            dataOutfitPage.length,
          )"
          :id="outfit.slug"
          :key="index"
          :switch-side="index % 2 == 0"
          :data-outfit="outfit"
        />
      </div>
    </div>
    <b-pagination
      v-if="true"
      v-model="currentPage"
      class="pagination-comment mt-4 container-max-width"
      :total-rows="dataOutfitPage.total"
      :per-page="dataOutfitPage.per_page"
      aria-controls="products-table"
      align="center"
      :hide-goto-end-buttons="true"
    ></b-pagination>
  </div>
</template>
<script>
import { BPagination } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { GA4ViewPromotion } from '@/GA4/events'

import CardOutfit from '@/components/Outfit/CardOutfit.vue'
import BreakinCupo from '@/components/Templates/BreakinCupo.vue'
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek.vue'
export default {
  components: {
    HeaderPageDerek,
    CardOutfit,
    BPagination,
    BreakinCupo,
  },
  data() {
    return {
      currentPage: 1,
      categorySelected: '',
      viewCampaigns: [],
    }
  },
  computed: {
    ...mapState('outfits', [
      'dataOutfitPage',
      'outfitCategorys',
      'outfitTitle',
    ]),
  },
  watch: {
    currentPage() {
      let query = Object.assign({}, this.$route.query)
      if (this.currentPage == 1) {
        delete query.page
        this.$router.push({ query: query })
      } else {
        query.page = this.currentPage
        this.$router.push({ query: query })
      }
    },
    $route() {
      if (this.$route.params.category) {
        this.categorySelected = this.$route.params.category
      } else {
        this.categorySelected = ''
      }
      this.getCatalogueOutfits({ url: this.$route.fullPath })
      window.scrollTo(0, 0)
    },
    dataOutfitPage() {
      document.title = 'Campañas | ' + this.outfitTitle
      if (this.dataOutfitPage) {
        setTimeout(() => {
          this.validateScrollCampaigns()
        }, 500)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.validateScrollCampaigns)
  },
  mounted() {
    this.getCatalogueOutfits({ url: this.$route.fullPath })
    if (this.$route.params.category) {
      this.categorySelected = this.$route.params.category
    } else {
      this.categorySelected = ''
    }
    document.title = 'Campañas | DEREK Tienda Online'
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.validateScrollCampaigns)
  },
  methods: {
    ...mapActions('outfits', ['getCategoryOutfits', 'getCatalogueOutfits']),
    validateScrollCampaigns() {
      this.dataOutfitPage.data.forEach(element => {
        let cardOutfit = document.getElementById(`${element.slug}`)
        if (
          cardOutfit &&
          !this.viewCampaigns.includes(element.slug) &&
          cardOutfit.getBoundingClientRect().top -
            (window.innerHeight - cardOutfit.offsetHeight / 3) <=
            0
        ) {
          this.viewCampaigns.push(element.slug)
          GA4ViewPromotion({
            nameCreative: `${element.campania_descripcion}`,
            slot: `page-card-outfit-${element.id}`,
            id: `${element.slug}`,
            name: element.nombre,
            items: [],
          })
        }
      })
    },
  },
}
</script>
