var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.outfitCategorys && _vm.dataOutfitPage)?_c('div',[_c('header-page-derek',{attrs:{"title":_vm.outfitTitle,"description":'¿Sabes que existe un outfit perfecto para cada ocasión, momento o día? Descubre la combinación perfecta de prendas en un solo lugar',"skeleton":false}}),_c('div',{staticClass:"nav-outfit container-max-width"},[_c('ul',{staticClass:"item-nav-outfit"},[_c('router-link',{staticClass:"category-title-outfit",attrs:{"to":{ name: 'Outfits' }}},[_c('li',{staticClass:"pe-3"},[_c('p',{staticClass:"name-category-outfit",class:_vm.categorySelected == '' ? 'select-outfit' : ''},[_vm._v(" Todos las campañas ")])])]),_vm._l((_vm.outfitCategorys),function(category,index){return _c('div',{key:index},[_c('router-link',{staticClass:"category-title-outfit",attrs:{"to":{
            name: 'OutfitsCategory',
            params: { category: category.slug },
          }}},[_c('li',{staticClass:"pe-3 d-flex align-items-center"},[_c('p',{staticClass:"pe-3 mb-0"},[_vm._v("/")]),_c('p',{staticClass:"name-category-outfit",class:category.slug == _vm.categorySelected ? 'select-outfit' : ''},[_vm._v(" "+_vm._s(category.descripcion)+" ")])])])],1)})],2)]),_c('div',[_c('div',{staticClass:"container-max-width"},_vm._l((_vm.dataOutfitPage.data.slice(0, 3)),function(outfit,index){return _c('card-outfit',{key:index + 'asd',attrs:{"id":outfit.slug,"switch-side":index % 2 == 0,"data-outfit":outfit}})}),1),_c('breakin-cupo',{staticClass:"mt-5"}),(_vm.dataOutfitPage.data.length > 3)?_c('div',{staticClass:"container-max-width"},_vm._l((_vm.dataOutfitPage.data.slice(
          3,
          _vm.dataOutfitPage.length,
        )),function(outfit,index){return _c('card-outfit',{key:index,attrs:{"id":outfit.slug,"switch-side":index % 2 == 0,"data-outfit":outfit}})}),1):_vm._e()],1),(true)?_c('b-pagination',{staticClass:"pagination-comment mt-4 container-max-width",attrs:{"total-rows":_vm.dataOutfitPage.total,"per-page":_vm.dataOutfitPage.per_page,"aria-controls":"products-table","align":"center","hide-goto-end-buttons":true},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }