<template>
  <div class="content-card-outfit">
    <div
      :style="isMovil ? '' : switchSide ? 'order: 1' : 'order: 2'"
      class="img-card-outfit"
    >
      <router-link
        :to="{
          name: 'OutfitDetail',
          params: {
            category: dataOutfit.url_outfit.split('-')[1],
            id: dataOutfit.url_outfit.split('-')[2],
            nameCreative: `${dataOutfit.campania_descripcion}`,
            slot: `page-card-outfit-${dataOutfit.id}`,
            idCampaign: `${dataOutfit.slug}`,
            name: dataOutfit.nombre,
          },
        }"
      >
        <img :src="dataOutfit.img_portada" alt="" />
      </router-link>
    </div>
    <div
      class="data-card-outfit"
      :style="isMovil ? '' : switchSide ? 'order: 2' : 'order: 1'"
    >
      <div class="container-data-card-outfit w-100">
        <div class="order-2 order-md-1 w-100">
          <h2 class="title-card-outfit">{{ dataOutfit.nombre }}</h2>
          <p class="is-card-outfit">{{ dataOutfit.descripcion }}</p>
          <div class="outfit-have-card-outfit">
            <p class="mb-2"><strong>¿Qué está usando?</strong></p>
            <p
              v-for="(product, index) in dataOutfit.referencias"
              :key="index + 'productos'"
              class="mb-0"
            >
              {{
                product.producto_slug
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
              <br />
            </p>
          </div>
        </div>
        <div class="ps-4 mb-3 ms-auto order-1 order-md-2">
          <span class="id-card-outfit">{{ dataOutfit.id }}</span>
        </div>
      </div>
      <div class="btns-actions-card-outfit">
        <router-link
          :to="{
            name: 'OutfitDetail',
            params: {
              category: dataOutfit.url_outfit.split('-')[1],
              id: dataOutfit.url_outfit.split('-')[2],
              nameCreative: `${dataOutfit.campania_descripcion}`,
              slot: `page-card-outfit-${dataOutfit.id}`,
              idCampaign: `${dataOutfit.slug}`,
              name: dataOutfit.nombre,
            },
          }"
          ><button class="btn-show-outfit">
            Quiero este outfit
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    switchSide: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataOutfit: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      isMovil: false,
    }
  },
  mounted() {
    if (window.innerWidth < 767) {
      this.isMovil = true
    }
  },
}
</script>
