var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-card-outfit"},[_c('div',{staticClass:"img-card-outfit",style:(_vm.isMovil ? '' : _vm.switchSide ? 'order: 1' : 'order: 2')},[_c('router-link',{attrs:{"to":{
        name: 'OutfitDetail',
        params: {
          category: _vm.dataOutfit.url_outfit.split('-')[1],
          id: _vm.dataOutfit.url_outfit.split('-')[2],
          nameCreative: `${_vm.dataOutfit.campania_descripcion}`,
          slot: `page-card-outfit-${_vm.dataOutfit.id}`,
          idCampaign: `${_vm.dataOutfit.slug}`,
          name: _vm.dataOutfit.nombre,
        },
      }}},[_c('img',{attrs:{"src":_vm.dataOutfit.img_portada,"alt":""}})])],1),_c('div',{staticClass:"data-card-outfit",style:(_vm.isMovil ? '' : _vm.switchSide ? 'order: 2' : 'order: 1')},[_c('div',{staticClass:"container-data-card-outfit w-100"},[_c('div',{staticClass:"order-2 order-md-1 w-100"},[_c('h2',{staticClass:"title-card-outfit"},[_vm._v(_vm._s(_vm.dataOutfit.nombre))]),_c('p',{staticClass:"is-card-outfit"},[_vm._v(_vm._s(_vm.dataOutfit.descripcion))]),_c('div',{staticClass:"outfit-have-card-outfit"},[_vm._m(0),_vm._l((_vm.dataOutfit.referencias),function(product,index){return _c('p',{key:index + 'productos',staticClass:"mb-0"},[_vm._v(" "+_vm._s(product.producto_slug .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" "),_c('br')])})],2)]),_c('div',{staticClass:"ps-4 mb-3 ms-auto order-1 order-md-2"},[_c('span',{staticClass:"id-card-outfit"},[_vm._v(_vm._s(_vm.dataOutfit.id))])])]),_c('div',{staticClass:"btns-actions-card-outfit"},[_c('router-link',{attrs:{"to":{
          name: 'OutfitDetail',
          params: {
            category: _vm.dataOutfit.url_outfit.split('-')[1],
            id: _vm.dataOutfit.url_outfit.split('-')[2],
            nameCreative: `${_vm.dataOutfit.campania_descripcion}`,
            slot: `page-card-outfit-${_vm.dataOutfit.id}`,
            idCampaign: `${_vm.dataOutfit.slug}`,
            name: _vm.dataOutfit.nombre,
          },
        }}},[_c('button',{staticClass:"btn-show-outfit"},[_vm._v(" Quiero este outfit ")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-2"},[_c('strong',[_vm._v("¿Qué está usando?")])])
}]

export { render, staticRenderFns }